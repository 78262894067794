import { lazy } from "react";
import Loadable from "../ui-component/Loadable";
const AuthGuard = Loadable(
  lazy(() => import("../views/pages/authentication/AuthGuard"))
);
const MainLayout = Loadable(lazy(() => import("../layout/MainLayout")));
const DashboardDefault = Loadable(
  lazy(() => import("../views/dashboard/Default"))
);
const MainOrders = Loadable(lazy(() => import("../views/order/MainOrders")));
const OrderDetails = Loadable(
  lazy(() => import("../views/order/OrderDetails"))
);

//store
const AddStore = Loadable(lazy(() => import("../views/store/AddStore")));
const ListStore = Loadable(lazy(() => import("../views/store/ListStore")));
const ChangePassword = Loadable(lazy(() => import("../views/pages/authentication/ChangePassword")));
const StoreSettings = Loadable(
  lazy(() => import("../views/store/StoreSettings"))
);
const StoreProfile = Loadable(
  lazy(() => import("../views/store/storeHome"))
);
const Addon = Loadable(
  lazy(() => import("../views/store/addons/Addon"))
);
const Brand = Loadable(
  lazy(() => import("../views/store/brand/brand"))
);
const MenuOptions = Loadable(
  lazy(() => import("../views/store/options/MenuOptions"))
);
const Category = Loadable(
  lazy(() => import("../views/store/category/Category"))
);
const Menu = Loadable(
  lazy(() => import("../views/store/menu/Menu"))
);
const MenuUpload = Loadable(
  lazy(() => import("../views/store/adminAction/MenuUpload"))
);

//order
const ReviewOrder = Loadable(
  lazy(() => import("../views/order/review/ReviewOrder"))
);
const DeliveryBoyAdd = Loadable(
  lazy(() => import("../views/deliveryBoy/DeliveryBoyAdd"))
);
const CustomerService = Loadable(
  lazy(() => import("../views/customerService/CustomerService"))
);

//offer
const ComboOffer = Loadable(
  lazy(() => import("../views/offer/ComboOffer"))
);
const DiscountOffer = Loadable(
  lazy(() => import("../views/offer/DiscountOffer"))
);
const StoreOffer = Loadable(
  lazy(() => import("../views/offer/StoreOffer"))
);
const CategoryOffer = Loadable(
  lazy(() => import("../views/offer/CategoryOffer"))
);
const ItemOffer = Loadable(
  lazy(() => import("../views/offer/ItemOffer"))
);
const NewCustomerCode = Loadable(
  lazy(() => import("../views/offer/NewCustomerCode"))
);

//performance Dialy
const DialyPerformance = Loadable(
  lazy(() => import("../views/report/performance/DialyPerformance"))
);
const WeeklyPerformance = Loadable(
  lazy(() => import("../views/report/performance/WeeklyPerformance"))
);
const MonthlyPerformance = Loadable(
  lazy(() => import("../views/report/performance/MonthlyPerformance"))
);

//Summary
const DialySummary = Loadable(
  lazy(() => import("../views/report/summary/DialySummary"))
);
const WeeklySummary = Loadable(
  lazy(() => import("../views/report/summary/WeeklySummary"))
);
const MonthlySummary = Loadable(
  lazy(() => import("../views/report/summary/MonthlySummary"))
);
//invoice
const MonthlyInvoice = Loadable(
  lazy(() => import("../views/report/MonthlyInvoice"))
);

//notification
const Notification = Loadable(
  lazy(() => import("../views/notification/Notification"))
);
const UserNotification = Loadable(
  lazy(() => import("../views/userNotification/userNotification"))
);

//complaits
const Complaints = Loadable(
  lazy(() => import("../views/feedback/Complaints"))
);

const CategoryTypes = Loadable(
  lazy(() => import("../views/settings/categoryTypes/CategoryTypes"))
);
const GroceryCategoryTypes = Loadable(
  lazy(() => import("../views/settings/GrocerycategoryTypes/groceryCategory"))
);
const StoreCusins = Loadable(
  lazy(() => import("../views/settings/cusins/StoreCusins"))
);

const ViewMenu = Loadable(
  lazy(() => import("../views/store/menu/ViewMenu"))
);

//audit trailas
const AuditTrails = Loadable(
  lazy(() => import("../views/audit/AuditTrails"))
);

//acount settings
const BranchPartner = Loadable(
  lazy(() => import("../views/accounts/branchPartner/BranchPartner"))
);

const CustomerCareAdmin = Loadable(
  lazy(() => import("../views/accounts/customercare/CustomerCareAdmin"))
);
const CustomerCareExecutive = Loadable(
  lazy(() => import("../views/accounts/customercare/CustomerCareExecutive"))
);

const AccountantAdmin = Loadable(
  lazy(() => import("../views/accounts/accountants/AccountantAdmin"))
);
const AccountsManager = Loadable(
  lazy(() => import("../views/accounts/accountants/AccountsManager"))
);
const AccountsExecutive = Loadable(
  lazy(() => import("../views/accounts/accountants/AccountsExecutive"))
);
const MarketingAdmin = Loadable(
  lazy(() => import("../views/accounts/marketing/MarketingAdmin"))
);
const MarketingExecutive = Loadable(
  lazy(() => import("../views/accounts/marketing/MarketingExecutive"))
);
const OfficeStaff = Loadable(
  lazy(() => import("../views/accounts/officeStaff/OfficeStaff"))
);

const Area = Loadable(
  lazy(() => import("../views/settings/area/Area"))
);
const Role = Loadable(
  lazy(() => import("../views/settings/role/Role"))
);
const Permission = Loadable(
  lazy(() => import("../views/settings/role/rolePermission"))
);
const StoreFirstOrder = Loadable(
  lazy(() => import("../views/offer/StoreFirstOrder/StoreFirstOrder"))
);
const TabaqCoupon = Loadable(
  lazy(() => import("../views/offer/couponCode/TabaqCoupon"))
);
const HomescreenBanner = Loadable(
  lazy(() => import("../views/banner/HomescreenBanner"))
);
const GuestNotificationHome = Loadable(
  lazy(() => import("../views/notification/homeGuestnotification"))
);

const CratisRegister = Loadable(
  lazy(() => import("../views/settings/cratis/CratisRegister"))
);

//online order reports
const OnlineOrder = Loadable(
  lazy(() => import("../views/report/onlineOrder/OnlineOrder"))
);

//pending Settilement
const PendingSettilement = Loadable(
  lazy(() => import("../views/report/settlement/PendingSettilement"))
);

//create
const CreateInvoice = Loadable(
  lazy(() => import("../views/report/settlement/CreateInvoice"))
);
const MachineStatus = Loadable(
  lazy(() => import("../views/settings/machine/machineStatus"))
);


const Machine = Loadable(
  lazy(() => import("../views/settings/machine/machines"))
);

const DeliveryBoyList = Loadable(
  lazy(() => import("../views/deliveryBoy/DeliveryBoyList"))
);
const CustomerOrder =  Loadable(
  lazy(() => import("../views/customerService/orderDetailsCustomer"))
);
const CreateTaxInvoice =  Loadable(
  lazy(() => import("../views/taxInvoice/create"))
);
const ViewTaxInvoice =  Loadable(
  lazy(() => import("../views/taxInvoice/view"))
);
const StoreOffcer =  Loadable(
  lazy(() => import("../views/accounts/storeOfficer/OfficeStaff"))
);

const TabaqOffer =  Loadable(
  lazy(() => import("../views/offer/tabaqOffer"))
);

const GuestNotification = Loadable(
  lazy(() => import("../views/notification/guestNotification"))
);

const ScanMenu = Loadable(
  lazy(() => import("../views/store/ScanMenu/ScanMenu"))
);
const StoreOrder =  Loadable(
  lazy(() => import("../views/store/storeOrder"))
);
const RejectedOrder =  Loadable(
  lazy(() => import("../views/order/rejectOrder/rejectForm"))
);
const GroceryMainorder = Loadable(
  lazy(() => import("../views/order/groceryOrder/MainOrders"))
)
const GroceryorderDetails = Loadable(
  lazy(() => import("../views/order/groceryOrder/OrderDetails"))
)

const BankDetails = Loadable(
  lazy(() => import("../views/bank/bank"))
)
const GroceryPendingorder = Loadable(
  lazy(() => import("../views/order/pendinggroceryOrder/MainOrders"))
)
const OrderDetailsPendingGrocery = Loadable(
  lazy(() => import("../views/order/pendinggroceryOrder/OrderDetails"))
);

const ViewStoreLocation = Loadable(
  lazy(() => import("../views/settings/storeLocation/ViewStoreLocation"))
);

const Configuration = Loadable(
  lazy(() => import("../views/settings/Homescreen/Banner"))
);
const AreaHome = Loadable(
  lazy(() => import("../views/settings/area/AreaMain"))
);
const DeliveryMap =  Loadable(
  lazy(() => import("../views/order/deliveryMap"))
);
const RejectedOrderGrocery =  Loadable(
  lazy(() => import("../views/order/groceryOrder/rejectedList"))
);
const BulkUpdate = Loadable(
  lazy(() => import("../views/store/adminAction/BulkUpdate"))
);
const CartHistory = Loadable(
  lazy(() => import("../views/customerService/cartHistory"))
);
const OfferReport = Loadable(
  lazy(() => import("../views/report/offerReport/offerReport"))
);
const OfferOrder= Loadable(
  lazy(() => import("../views/report/offerReport/offerOrder"))
);
const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/dashboard",
      element: <DashboardDefault />,
    },
    {
      path: "/store",
      children: [
        {
          path: "add",
          element: <AddStore />,
        },
        {
          path: "list",
          element: <ListStore />,
        },
        {
          path: "settings",
          element: <StoreSettings />,
        },
        {
          path: "storeProfile/:id",
          element: <StoreProfile />,
        },
        {
          path: "storeProfile/addons/:id",
          element: <Addon />,
        },
        {
          path: "storeProfile/brands/:id",
          element: <Brand />,
        },
        {
          path: "storeProfile/options/:id",
          element: <MenuOptions />,
        },
        {
          path: "storeProfile/category/:id",
          element: <Category />,
        },
        {
          path: "storeProfile/menu/:id",
          element: <Menu />,
        },
        {
          path: "storeProfile/viewMenu/:storeId/:catID",
          element: <ViewMenu />,
        },
        {
          path: "menuUpload",
          element: <MenuUpload />,
        },
        {
          path : "scanMenu",
          element: <ScanMenu />,
        },
        {
          path : "bulkupdate",
          element: <BulkUpdate />,
        },
        {
          path: "storeorder/:id",
          element: <StoreOrder />,
        },
      ],
    },
    {
      path: "/deliveryBoy",
      children: [
        {
          path: "add",
          element: <DeliveryBoyAdd />,
        },
        {
          path: "list",
          element: <DeliveryBoyList />,
        },
      ],
    },
    {
      path: "/settings",
      children: [
        {
          path: "category-types",
          element: <CategoryTypes />,
        },
        {
          path: "grocery-category-types",
          element: <GroceryCategoryTypes />,
        },
        {
          path: "cusins",
          element: <StoreCusins />,
        },
        {
          path: "area",
          element: <AreaHome />,
        },
        {
          path: "cratis",
          element: <CratisRegister />,
        },
        {
          path: "role",
          element: <Role />,
        },
        {
          path: "permission",
          element: <Permission />,
        },
        {
          path: "storeLocation",
          element: <ViewStoreLocation />,
        },
        {
          path: "configuration",
          element: <Configuration />,
        },
      ],
    },
    {
      path: "/customerService",
      // element: <CustomerService />,
      children: [
        {
          path: "list",
          element: <CustomerService />,
        },
        {
          path: "customerorder/:id",
          element: <CustomerOrder />,
        },
        {
          path: "carthistory/:id/:type",
          element: <CartHistory />,
        },
        
      ],

    },
    {
      path: "/branch",
      children: [
        {
          path: "order",
          element: <MainOrders />,
        },
      ],
    },
    {
      path: "/order",
      children: [
        {
          path: "view",
          element: <MainOrders />,
        },
        {
          path: "grocery/view",
          element: <GroceryMainorder />,
        },
        {
          path: "grocery/pending",
          element: <GroceryPendingorder />,
        },
        {
          path: "grocery/groceryPendingOrderDetails/:id",
          element: <OrderDetailsPendingGrocery />,
        },
        {
          path: "grocery/groceryOrderDetails/:id",
          element: <GroceryorderDetails />,
        },
        {
          path: "review",
          element: <ReviewOrder />,
        },
        {
          path: "orderDetails/:id",
          element: <OrderDetails />,
        },
        {
          path: "onlineOrder",
          element: <OrderDetails />,
        },
        {
          path: "rejected",
          element: <RejectedOrder />,
        },
        {
          path: "deliverymap/:id/:type",
          element: <  DeliveryMap />,
        },
        {
          path: "grocery/rejected",
          element: <RejectedOrderGrocery />,
        },
        
      
        
      ],
    },
    {
      path: "/offer",
      children: [
        {
          path: "combo_offer",
          element: <ComboOffer />,
        },
        {
          path: "discount",
          element: <DiscountOffer />,
        },
        {
          path: "store",
          element: <StoreOffer />,
        },
        {
          path: "category",
          element: <CategoryOffer />,
        },
        {
          path: "item",
          element: <ItemOffer />,
        },
        {
          path: "newCustomer",
          element: <NewCustomerCode />,
        },
        {
          path: "StoreNewOrderOffer",
          element: <StoreFirstOrder />,
        },
        {
          path: "coupon-code",
          element: <TabaqCoupon />,
        },
        {
          path: "tabaq",
          element: <TabaqOffer />,
        },
      ],
    },
    {
      path: "/report",
      children: [
        {
          path: "performance_report",
          children: [
            {
              path: "dialy",
              element: <DialyPerformance />,
            },
            {
              path: "weekly",
              element: <WeeklyPerformance />,
            },
            {
              path: "monthly",
              element: <MonthlyPerformance />,
            },
          ]
        },
        {
          path: "summary_report",
          children: [
            {
              path: "dialy",
              element: <DialySummary />,
            },
            {
              path: "weekly",
              element: <WeeklySummary />,
            },
            {
              path: "monthly",
              element: <MonthlySummary />,
            },
          ]
        },
        {
          path: "pending-settlement",
          element: <PendingSettilement />,
        },
        {
          path: "invoice",
          element: <MonthlyInvoice />,
        },
        {
          path: "onlineOrder",
          element: <OnlineOrder />,
        },
        {
          path: "create-invoice",
          element: <CreateInvoice />,
        },
        {
          path: "offerreport",
          element: <OfferReport />,
        },
        {
          path: "offerorder/:id",
          element: <OfferOrder />,
        },
        
        
      ],
    },
    {
      path: "/notification/:type",
      element: <Notification />,
      // children: [
      //   {
      //     path: "customer",
      //     element: <Notification />,
      //   },
      //   {
      //     path: "area",
      //     element: <Notification />,
      //   },
      //   {
      //     path: "scheduled",
      //     element: <Notification />,
      //   },]
    },
    {
      path: "/notification/guest",
      element: <GuestNotificationHome />,
    },
    {
      path: "/feedback",
      element: <Complaints />,
    },
    {
      path: "/banner",
      element: <HomescreenBanner />,
    },
    {
      path: "/profile",
      element: <ChangePassword />,
    },
    {
      path: "/audit-tails",
      element: <AuditTrails />,
    },
    {
      path: "/accounts",
      children: [
        {
          path: "branch-partner",
          element: <BranchPartner />,
        },
        {
          path: "custumercare-admin",
          element: <CustomerCareAdmin />,
        },
        {
          path: "custumercare-executive",
          element: <CustomerCareExecutive />,
        },
        {
          path: "accountant-admin",
          element: <AccountantAdmin />,
        },
        {
          path: "accounts-manager",
          element: <AccountsManager />,
        },
        {
          path: "accounts-executive",
          element: <AccountsExecutive />,
        },
        {
          path: "marketing-admin",
          element: <MarketingAdmin />,
        },
        {
          path: "marketing-executive",
          element: <MarketingExecutive />,
        },
        {
          path: "office-staff",
          element: <OfficeStaff />,
        },
        {
          path: "store-officer",
          element: <StoreOffcer />,
        }
      ],
    },
    {
      path: "/settings",
      children: [
        {
          path: "status",
          element: <MachineStatus/>,
        },
        {
          path: "machine",
          element: <Machine />,
        },
        // {
        //   path: "machine/history",
        //   element: <History />,
        // },
      ]
    },
    {
      path: "/notification/view",
      element: <UserNotification />,
    },
    {
      path: "/taxinvoice",
      children: [
        {
          path: "view",
          element: <ViewTaxInvoice />,
        },
        {
          path: "create",
          element: <CreateTaxInvoice />,
        },
      ],
    },

    {
      path: "/bankdetails/view",
      element: <BankDetails />,
    },
  ],
};

export default MainRoutes;
